import $ from 'jquery';
import owlCarousel from 'owl.carousel';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

var participants_stories_carousel = $("body.home .participants_stories_carousel.owl-carousel");

$(document).ready(function() {
  participants_stories_carousel.owlCarousel({
    loop: true,
    dots: true,
    responsiveClass: true,
    mouseDrag: false,
    dotsContainer: '#carousel-custom-dots',
    responsive: {
      0: {
        items: 1,
      }
    }
  });
});

$('body.home #carousel_custom_dots .slider_button').click(function () {
  $(".slider_buttons .slider_button").removeClass("active");
  $(this).addClass("active");
  participants_stories_carousel.trigger('to.owl.carousel', [$(this).index(), 300]);
});
