$('div.filter-mtg-type>form').on('submit', function(e){
  e.preventDefault();
  var no_checkboxes = $(this).find('input[type="checkbox"]').length;
  var no_checked = $(this).find('input[type="checkbox"]:checked').length;

  if (no_checked < 1) {
    $('ul.meetings li').show();
    $('.calendar a.meeting').show();
  } else {
    $('div.filter-mtg-type>form').find('input').each(function(){
      var mtg_type = $(this).val();
      //if checked
      if ($(this).prop('checked')){
        console.log('show '+mtg_type);
        $('ul.meetings li.'+mtg_type).show();
        $('.calendar a.meeting.'+mtg_type).show();
      } else {
        //not checked
        console.log('hide '+mtg_type);
        $('ul.meetings li.'+mtg_type).hide();
        $('.calendar a.meeting.'+mtg_type).hide();
      }
    })
  }
})

$('div.filter-mtg-type>form input').on('change', function(){
  $(this).parents('form').submit();
});
