import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

$(".contact_button").on("click", function () {
  var button_index = $(this).index();
  $(".button_area").removeClass("active");
  $(".contact_button").removeClass("active");
  $(".button_area").eq(button_index).addClass("active");
  $(this).addClass("active");

  //scroll to
  let button_area_top = $(".hidden_button_areas").offset().top;
  console.log(button_area_top);

  $('body, html').animate({scrollTop: button_area_top}, 400);

});
