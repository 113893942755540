$("#one_time_donation").on("click", function() {
  $(".donation_link").removeClass("active");
  $(".donation_product").removeClass("active");
  $(this).addClass("active");
  $("#product409").addClass("active");
});

$("#recurring_donation").on("click", function() {
  $(".donation_link").removeClass("active");
  $(".donation_product").removeClass("active");
  $(this).addClass("active");
  $("#product438").addClass("active");
}); 