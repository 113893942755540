// //Replace Text on Nav Menu hide
// var str = $('a.ubermenu-responsive-toggle').html();
// //console.log(str);
// var new_str = str.replace('Menu', '');
// //console.log(new_str);
// $('a.ubermenu-responsive-toggle').html(new_str);

$(document).on("click", "div.table_button.edit_details", function () {
  var meeting_name = $(this).closest("tr").find(".table_title").text();
  $("#input_15_1").val(meeting_name);
  $("div.action_form").removeClass("active");
  $(".form_edit_details").addClass("active");
});

$(document).on("click", "div.table_button.cancel_meeting", function () {
  var meeting_name = $(this).closest("tr").find(".table_title").text();
  $("#input_16_1").val(meeting_name);
  $("div.action_form").removeClass("active");
  $(".form_cancel_meeting").addClass("active");
});

$(document).on("click", "div.table_button.end_meeting", function () {
  var meeting_name = $(this).closest("tr").find(".table_title").text();
  $("#input_17_1").val(meeting_name);
  $("div.action_form").removeClass("active");
  $(".form_end_meeting").addClass("active");
});

$("div.action_form .close_button").on("click", function () {
  $(this).closest(".action_form").removeClass("active");
});

$(".team_member .team_image").on("click", function () {
  $(this).parent().next().addClass("active");
});

$(document).on("click", "#close_team_modal", function () {
  $(this).parent().parent().removeClass("active");
});
