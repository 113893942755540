// import $ from 'jquery';
// //attach jquery to dom
// window.jQuery = $;
// window.$ = $;

// Clicking on the title/label to collapse/expand
$(".expandable_areas .label").on("click", function () {
  console.log("Clicked - label");
  if (!$(this).next().hasClass("active")) {
    $(this).next().addClass("active");
    $(this).parent().next().addClass("active");
  } else {
    $(this).next().removeClass("active");
    $(this).parent().next().removeClass("active");
  }
});

// Clicking on the arrow button to collapse/expand
$(".expandable_areas .expand_arrow").on("click", function() {
  console.log("Clicked - arrow");
  if (!$(this).prev().find(".content").hasClass("active")) {
    $(this).prev().find(".content").addClass("active");
    $(this).addClass("active");
  } else {
    $(this).prev().find(".content").removeClass("active");
    $(this).removeClass("active");
  }
});

// CONTACT PAGE - revealing expandable group upon header click
$(".expandable_areas .expandable_header").on("click", function() {
  console.log("Clicked - arrow");
  if (!$(this).hasClass("active")) {
    $(".expandable_area").css("display", "block");
    $(this).addClass("active");
    $(this).find(".expand_arrow").addClass("active");
  } else {
    $(".expandable_area").css("display", "none");
    $(this).removeClass("active");
    $(".expandable_area .content").removeClass("active");
    $(".expand_arrow").removeClass("active");
  }
});
